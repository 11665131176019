export default [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "仓库",
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "客户",
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "出库通知单号",
    dataIndex: "delivery_order_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "收费项目",
    dataIndex: "charge_item",
    width: 120,
    ellipsis: true,
 },
  {
    title: "费用金额(元)",
    dataIndex: "total_amount",
    width: 100,
    ellipsis: true,
 },
  {
    title: "状态",
    dataIndex: "status",
    width: 100,
    ellipsis: true,
    customRender: (value, item) => (item.is_void ? "已作废" : "已完成"),
  },
  {
    title: "创建人",
    dataIndex: "creator_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    width: 100,
  },
];
